import {pageService, stateEventService} from 'core-tracking-objects';
import {scPage} from '../stockcars-utils-bundle';
import {getDetailsPageVehicleData} from './tracking-utils';
import {STCK_STORE, SELECTORS, ACTIONS} from '@oneaudi/stck-store';

class PageObjectStockcars {
	/**
	 * initialize class
	 * @returns {void} returns nothing
	 */
	initialize() {
		// activate stck page-tracking on stck pages only
		if (SETUPS.get('stockcar.context')) {
			this.patchEventName = this.patchEventName.bind(this);
			this.getTrackingData = this.getTrackingData.bind(this);

			this.isInitialPageLoad = true;
			this.shouldResetTrackingState = false;
			this.getTrackingData = this.getTrackingData.bind(this);
			stateEventService.register(this.patchEventName);
			pageService.register(this.getTrackingData);
		}
	}

	async patchEventName() {
		const isInitialPageLoad = this.isInitialPageLoad;
		this.isInitialPageLoad = false;
		const layer = document.querySelector('.modal-layer[data-layer-active="true"], .nm-layer-opened');
		const isDetailsLayer = (location.hash || '').indexOf('sc_detail') >= 1;
		const campaignId = scPage.getUrlParameterByName('campaignId', location.href);

		if (!!campaignId) {
			return PageObjectStockcars._getEventInfo(campaignId, isInitialPageLoad);
		}

		if (layer && !isDetailsLayer) {
			return {};
		}

		return PageObjectStockcars._getEventInfo(campaignId, isInitialPageLoad);
	}

	/**
	 * getTrackingData - collects the relevant tracking information
	 * @returns {Promise} promise - returns promise for pageObjectConfigurator
	 */

	async getTrackingData() {
		try {
			if (scPage.isDetailsPage(location.href)) {
				this._detailPageVehicle = await getDetailsPageVehicleData();
			}

			const applicationName = PageObjectStockcars._getApplicationName();
			const pageAttributesData = this._getPageAttributesData();
			const attributes = {applicationName, ...pageAttributesData};

			const pageInfo = SELECTORS.TRACKING.getTrackingStateRaw(STCK_STORE.state);
			this.resetTrackingStoreData(pageInfo);

			return {attributes, pageInfo};
		}
		catch (e) {
			console.log(e);
			return {};
		}
	}

	resetTrackingStoreData(pageInfo) {
		if (!this.shouldResetTrackingState) {
			this.shouldResetTrackingState = true;
			const {
				// eslint-disable-next-line no-unused-vars
				destinationURL,
				...pageInfoWithoutDestinationUrl
			} = pageInfo;
			STCK_STORE.dispatch(ACTIONS.TRACKING.addTrackingParams({...pageInfoWithoutDestinationUrl}));
		}
	}

	/**
	 * _getEventInfo
	 * @param {string} campaignId campaignId
	 * @param {boolean} isInitialPageLoad isInitialPageLoad
	 * @returns {{eventName: string, eventAction: string}} event Info
	 * @private
	 */
	static _getEventInfo(campaignId, isInitialPageLoad = false) {
		const useCampaignValues = (!!campaignId && !isInitialPageLoad);
		const eventInfo = {
			eventAction: useCampaignValues ? 'step' :'page_load',
			eventName: useCampaignValues ? 'vtp campaign' : PageObjectStockcars._getEventName(campaignId)
		};
		return {eventInfo};
	}

	/**
	 * _getEventName
	 * @returns {string} event name
	 * @private
	 */
	static _getEventName() {
		if (scPage.isFavoritesPage()) {
			return 'vtp favorites';
		}

		if (scPage.isDetailsPage(location.href)) {
			return 'vtp details';
		}

		if (scPage.isComparePage()) {
			return 'vtp compare';
		}

		if (scPage.isFilterPage()) {
			const urlFileName = location.href.match(/[^/]+$/);
			if (urlFileName && urlFileName[0]) {
				const filterPageName = urlFileName[0].split('.')[0];
				return `vtp ${filterPageName}`;
			}
			return 'vtp filter';
		}

		if (scPage.isResultsPage()) {
			return 'vtp results';
		}

		return 'vtp';
	}

	/**
	 * _getPageAttributesData
	 * @returns {object} pageAttributes pageAttributes
	 * @private
	 */
	_getPageAttributesData() {
		const pageAttributes = {
			site: 'carsearch',
			applicationName: SETUPS.get('stockcar.application.name') || '',
			label: '',
			targetUrl: '',
			clickId: '',
			elementName: '',
			value: '',
			pos: '',
			currentURL: location.href,
			componentName: ''
		};

		const campaignId = scPage.getUrlParameterByName('campaignId', location.href);
		if (campaignId) {
			pageAttributes.promotionName = campaignId;
		}

		if (scPage.isDetailsPage(location.href)) {
			if (this._detailPageVehicle && this._detailPageVehicle.dealer) {
				return {
					entryDealerId: this._detailPageVehicle.dealer.id || '',
					entryDealerName: this._detailPageVehicle.dealer.name || '',
					entryDealerLocation: this._detailPageVehicle.dealer.city || '',
					currency: (this._detailPageVehicle.typedPrices && this._detailPageVehicle.typedPrices.length) ? this._detailPageVehicle.typedPrices[0].currencyCode : '',
					...pageAttributes
				};
			}
		}

		return pageAttributes;
	}

	/**
	 * _getApplicationName
	 * @returns {string} returns string with vtp value
	 */
	static _getApplicationName() {
		let type = SELECTORS.VEHICLES.getVehiclesType(STCK_STORE.state);
		return type ? `vtp-${type.toLowerCase()}` : 'vtp';
	}

}

const pageObjectStockcars = new PageObjectStockcars();
export {pageObjectStockcars, PageObjectStockcars};
