import {productService} from 'core-tracking-objects';
import {getProductsMap, getProducts} from './tracking-utils';

class ProductObjectStockcars {
	/**
	 * initialize class
	 * @returns {void} returns nothing
	 */
	initialize() {
		this.getTrackingData = this.getTrackingData.bind(this);
		productService.register(this.getTrackingData);
	}

	/**
	 * getTrackingData - core-tracking registered callback function
	 * @returns {Promise<Array>} product data array
	 */
	async getTrackingData() {
		const products = await getProductsMap();
		return getProducts(products);
	}
}

/**
 *
 * @type {ProductObjectStockcars} Instance
 */
const productObjectStockcars = new ProductObjectStockcars();
export {productObjectStockcars, ProductObjectStockcars};
