import {stateEventService} from 'core-tracking-objects';

class LayerTrackingStockcars {
	static get layerEventNames() {
		return {
			'MODAL-MANDATORY-AREA-SEARCH-LAYER-ELEMENT': 'vtp location layer',
			'MODAL-LAYER-FINANCE-ELEMENT': 'vtp finance',
			'MODAL-CONSUMPTION-EMISSION-LAYER-ELEMENT': 'vtp consumption layer'
		};
	}

	static get layerEvent() {
		return [
			'LAYER_LOADED',
			'LAYER_CLOSED'
		];
	}

	initialize() {
		this.handleLayerTracking = this.handleLayerTracking.bind(this);
		stateEventService.register(this.handleLayerTracking);

		this.currentLayerType = null;
	}

	handleLayerTracking(eventType) {
		let result = {};
		if (eventType && eventType === LayerTrackingStockcars.layerEvent[0]) {
			const modalLayer = document.querySelector('.modal-layer__content');
			if (modalLayer && modalLayer.hasChildNodes()) {
				const modalLayerName = modalLayer.firstElementChild.tagName;
				result = {eventInfo: {eventName: LayerTrackingStockcars.layerEventNames[modalLayerName]}};

				this.currentLayerType = modalLayerName;
			}
		}
		else if (eventType && eventType === LayerTrackingStockcars.layerEvent[1]) {
			result = {eventInfo: {eventAction: 'step'}};

			this.currentLayerType = null;
		}

		return result;
	}
}

const layerTrackingStockcars = new LayerTrackingStockcars();
export {layerTrackingStockcars, LayerTrackingStockcars};
