import {searchService} from 'core-tracking-objects';
import {scPage} from '../stockcars-utils-bundle';
import {SELECTORS, STCK_STORE} from '@oneaudi/stck-store';

class SearchObjectStockcars {
	static get defaults() {
		return {
			selectorFilterItem: '.sc-filter-item',
			selectorFilterRange: '.sc-filter-range',
			selectorGeoFilter: '.sc-lf-map-container'
		};
	}

	/**
	 * initialize class
	 * @returns {void} returns nothing
	 */
	initialize() {
		// activate stck search-tracking on stck pages only
		if (SETUPS.get('stockcar.context')) {
			STCK_STORE.observeStateFromStore(SELECTORS.FILTERS.getFilterItemsRaw, this.handleFilterUpdate);
			this.getTrackingData = this.getTrackingData.bind(this);
			searchService.register(this.getTrackingData);
		}
	}

	handleFilterUpdate() {
		const searchUpdated = new CustomEvent('SEARCH_UPDATED');
		document.dispatchEvent(searchUpdated);
	}

	getTrackingData() {
		return scPage.isStockcarPage() ? this._getSearchData() : {};
	}

	/**
	 * _getSearchData
	 * @returns {Promise<{filter: *, name: string, term: string, results: number}>} searchData search data
	 * @private
	 */
	_getSearchData() {
		try {
			return Promise.resolve({
				name: `vtp search - ${SELECTORS.VEHICLES.getVehiclesType(STCK_STORE.state).toLowerCase()}`,
				term: '',
				results: STCK_STORE.state.totalCount,
				filter: this._getAllActivatedFilterOnCurrentPage()
			});
		}
		catch (err) {
			// console.err(err);
			return null;
		}
	}

	/**
	 * _getAllActivatedFilterOnCurrentPage
	 * @returns {array} unique array with activated filters on current page
	 * @private
	 */
	_getAllActivatedFilterOnCurrentPage() {
		const activeFiltersOnCurrentPage = [];
		if (scPage.isFilterPage()) {
			const filtersMap = SELECTORS.FILTERS.getAllActiveFiltersMap(STCK_STORE.state);
			for (const [id, {values = []}] of filtersMap.entries()) {
				activeFiltersOnCurrentPage.push({id, values});
			}
		}
		return activeFiltersOnCurrentPage;
	}
}

/**
 *
 * @type {SearchObjectStockcars} Instance
 */
const searchObjectStockcars = new SearchObjectStockcars();
export {searchObjectStockcars, SearchObjectStockcars};
