import {dealerService} from 'core-tracking-objects';
import {getProductsMap} from './tracking-utils';

class DealerObjectStockcars {
	/**
	 * initialize class
	 * @returns {void} returns nothing
	 */
	initialize() {
		// activate stck page-tracking on stck pages only
		if (SETUPS.get('stockcar.context')) {
			this.getTrackingData = this.getTrackingData.bind(this);
			dealerService.register(this.getTrackingData);
		}
	}

	/**
	 * getTrackingData - collects the relevant tracking information
	 * @returns {Promise} promise - returns promise for pageObjectConfigurator
	 */

	async getTrackingData() {
		const products = await getProductsMap();
		return this._getDealer(products);
	}

	/**
	 * _getDealer - generated dealer items
	 * @param {Map} vehicleMap vehicles Map
	 * @returns {Array} dealerData dealer items array
	 * @private
	 */
	_getDealer(vehicleMap = new Map()) {
		let dealerItems = [];

		vehicleMap.forEach(vehicle => {
			dealerItems.push({
				dealerInfo: {
					dealerId: vehicle.dealer ? vehicle.dealer.trackingId : '',
					dealerName: vehicle.dealer ? vehicle.dealer.name : ''
				},
				attributes: {
					location: vehicle.dealer ? vehicle.dealer.city : ''
				}
			});
		});

		return dealerItems;
	}
}

const dealerObjectStockcars = new DealerObjectStockcars();
export {dealerObjectStockcars, DealerObjectStockcars};
