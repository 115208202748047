import {vehiclesController} from '../stockcars-bundle';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {scPage} from '../stockcars-utils-bundle';

/**
 * @method _getVehicleIdFromUrl
 * @param {string} url url
 * @returns {string} returns only the last id (needed for detail page in layer on top of detail page)
 * @private
 */
export const getVehicleIdFromUrl = (url = location.href) => {
	const regex = /(sc_detail.*?\.)(([^.\s])+)/gi;
	let vehicleIds = [];
	let matchesArr;

	while ((matchesArr = regex.exec(url))) {
		vehicleIds.push(matchesArr[2]);
	}

	return vehicleIds[vehicleIds.length - 1] || '';
};

/**
 * getDetailsPageVehicleData
 * @returns {Promise<object | null>} vehicle vehicle object
 */
export const getDetailsPageVehicleData = async () => {
	const vehicleId = getVehicleIdFromUrl();
	return await vehiclesController.loadVehicleById(vehicleId).catch(error => console.error(error));
};

/**
 * getMatchingVehicles
 * @returns {object} matching vehicles
 */
export const getMatchingVehicles = async () => {
	if (!document.querySelector('sc-suggested-cars-element')) {
		return;
	}
	const lastVisitedVehiclesMap = await STCK_STORE.waitForStateFromStorePromise({
		conditionFn: (state) => !!SELECTORS.VEHICLES.getLastVisitedVehicleIdsState(state).length && state.vehicles.length,
		selectorToReturn: SELECTORS.VEHICLES.getLastVisitedVehiclesMap
	}).catch(err => new Map()); // eslint-disable-line

	const matchingVehiclesMap = await STCK_STORE.waitForStateFromStorePromise({
		conditionFn: (state) => !!SELECTORS.VEHICLES.getMatchingVehicleIdsState(state).length && state.vehicles.length,
		selectorToReturn: SELECTORS.VEHICLES.getSuggestedVehiclesMap
	}).catch(err => new Map()); // eslint-disable-line

	const matchingVehiclesFromDealerMap = await STCK_STORE.waitForStateFromStorePromise({
		conditionFn: (state) => !!SELECTORS.VEHICLES.getMatchingVehicleIdsFromDealerState(state).length && state.vehicles.length,
		selectorToReturn: SELECTORS.VEHICLES.getDealerVehiclesMap
	}).catch(err => new Map()); // eslint-disable-line

	return {lastVisitedVehiclesMap, matchingVehiclesMap, matchingVehiclesFromDealerMap};
};

/**
 * getMappedCompareProducts
 * @param {object} compareVehicles compareVehicles
 * @returns {Array} returns array of products
 */
function getMappedCompareProducts(compareVehicles = {}) {
	const products = [];

	if (compareVehicles.basic && compareVehicles.dealer) {
		const vehiclesFromStore = SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state);
		for (let i = 0; i < compareVehicles.basic.length; i++) {
			if (vehiclesFromStore.has(compareVehicles.basic[i].vehicle_id)) {
				products.push(vehiclesFromStore.get(compareVehicles.basic[i].vehicle_id));
			}
			else {
				const tempProduct = {};
				tempProduct.id = compareVehicles.basic[i].vehicle_id || '';
				tempProduct.modelcode = {
					description: compareVehicles.basic[i].title || ''
				};
				tempProduct.type = '';
				tempProduct.dealer = {
					trackingId: compareVehicles.dealer[i].dealerInfo.id || '',
					name: compareVehicles.dealer[i].dealerInfo.name || '',
					city: compareVehicles.dealer[i].dealerInfo.city || ''
				};

				products.push(tempProduct);
			}
		}
	}

	return products;
}

/**
 * getCompareProducts
 * @returns {Promise<* | Map<any, any>>} compare products
 */
async function getCompareProducts() {
	const compareResults = await STCK_STORE.waitForStateFromStorePromise({
		conditionFn: (state) => !!Object.keys(SELECTORS.VEHICLES.getCompareVehicles(state)).length,
		selectorToReturn: SELECTORS.VEHICLES.getCompareVehicles
	}).catch(err => new Map()); // eslint-disable-line

	return getMappedCompareProducts(compareResults);
}

/**
 * getFavoriteProducts
 * @returns {Promise<* | Map<any, any>>} favorite products
 */
async function getFavoriteProducts() {
	return await STCK_STORE.waitForStateFromStorePromise({
		conditionFn: (state) => !!SELECTORS.VEHICLES.getFavoriteVehicleIdsState(state).length && (state.vehicles.length >= SELECTORS.VEHICLES.getFavoriteVehicleIdsState(state).length),
		selectorToReturn: SELECTORS.VEHICLES.getFavoriteVehiclesMap
	}).catch(err => new Map()); // eslint-disable-line
}

/**
 * getResultsPageProducts
 * @returns {Promise<* | Map<any, any>>} results page products
 */
async function getResultsPageProducts() {
	return await STCK_STORE.waitForStateFromStorePromise({
		conditionFn: (state) => !!SELECTORS.VEHICLES.getResultVehicleIdsState(state).length && state.vehicles.length,
		selectorToReturn: SELECTORS.VEHICLES.getResultVehiclesMap
	}).catch(err => new Map()); // eslint-disable-line
}

/**
 * getDetailsPageProducts
 * @returns {Promise<Map<any, any>>} details page products
 */
async function getDetailsPageProducts() {
	const detailPageVehicle = await getDetailsPageVehicleData();
	if (detailPageVehicle) {
		const detailPageVehicleMap = new Map().set(detailPageVehicle.id, detailPageVehicle);
		return detailPageVehicleMap;
	}
	return new Map();
}

/**
 * getProductsMap
 * @returns {Promise<*|Map<any, any>|Map<any, any>>} products map
 */
export const getProductsMap = async () => {
	if (scPage.isDetailsPage(location.href)) {
		return await getDetailsPageProducts();
	}
	else if (scPage.isResultsPage()) {
		return await getResultsPageProducts();
	}
	else if (scPage.isFavoritesPage()) {
		return await getFavoriteProducts();
	}
	else if (scPage.isComparePage()) {
		return await getCompareProducts();
	}
	return new Map();
};

export const ProviderTypes = {
	audiAg: 'AUDI AG',
	dealer: 'Dealer',
	employee: 'Employee'
};

const getProvider = (vehicle) => {
	if (vehicle.nationWideSelling === true){
		return ProviderTypes.audiAg;
	}

	if (vehicle.employeeVehicle === true){
		return ProviderTypes.employee;
	}

	return ProviderTypes.dealer;
};

const hasRetailPrice = (vehicle) =>
	vehicle.typedPrices
	&& (vehicle.typedPrices.some(price => price.type === 'retail') || vehicle.typedPrices.length > 1);


const getEnhancedVehicle = (vehicle = {}) => {
	const enrichedVehicle = {...vehicle};

	if (vehicle.type === 'N') {
		enrichedVehicle.provider = getProvider(vehicle);
		enrichedVehicle.retailPrice = hasRetailPrice(vehicle);
	}

	return enrichedVehicle;
};

/**
 * _getProducts - generates each product items and returns productData array
 * @param {Map} vehicles vehicles array
 * @returns {Array} productData productData
 * @private
 */
export const getProducts = (vehicles = new Map()) => {
	let productData = [];

	vehicles.forEach(vehicle => {
		if (vehicle) {
			productData.push({
				productInfo: {
					productId: vehicle.id || '',
					productName: vehicle.modelCode ? vehicle.modelCode.description : '',
					manufacturer: 'Audi'
				},
				category: {
					primaryCategory: vehicle.symbolicCarlineGroup ? vehicle.symbolicCarlineGroup.code : '',
					subCategory1: vehicle.symbolicCarline ? vehicle.symbolicCarline.code : '',
					productType: vehicle.type === 'N' ? 'new car' : 'used car'
				},
				attributes: getEnhancedVehicle(vehicle)
			});
		}
	});

	return productData;
};

/**
 * getRelatedProduct
 * @param {string} vehicleId vehicleId
 * @returns {array|undefined} products index
 */
export const getRelatedProduct = (vehicleId = '') => {
	if (!vehicleId) {
		return undefined;
	}
	const productsMapFromStore = SELECTORS.VEHICLES.getVehiclesMapByIds(STCK_STORE.state, [vehicleId]);
	const products = getProducts(productsMapFromStore);
	return products.length ? products[0] : undefined;
};


