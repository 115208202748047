import {pageObjectStockcars} from './stockcars-tracking/page-object-stockcars';
import {productObjectStockcars} from './stockcars-tracking/product-object-stockcars';
import {clickTrackingStockcars} from './stockcars-tracking/click-tracking-stockcars';
import {layerTrackingStockcars} from './stockcars-tracking/layer-tracking-stockcars';
import {searchObjectStockcars} from './stockcars-tracking/search-object-stockcars';
import {clickHandlers} from './stockcars-tracking/click-tracking-handler';
import {dealerObjectStockcars} from './stockcars-tracking/dealer-object-stockcars';

const toBeInitialized = [
	pageObjectStockcars,
	productObjectStockcars,
	layerTrackingStockcars,
	searchObjectStockcars,
	clickTrackingStockcars,
	dealerObjectStockcars
];

toBeInitialized.forEach(module => {
	module.initialize();
});

export {
	clickHandlers,
	pageObjectStockcars,
	productObjectStockcars,
	clickTrackingStockcars,
	layerTrackingStockcars,
	searchObjectStockcars,
	dealerObjectStockcars
};
