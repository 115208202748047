/**
 * _createEventInfo
 * @param {string} eventAction eventAction
 * @param {string} eventName event name
 * @return {object} event info object
 * @private
 */
import {scPage} from '../stockcars-utils-bundle';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {getRelatedProduct} from './tracking-utils';

/**
 * getCleanedObject - remove all key-value pairs where value is undefined
 * @param {object} obj object to be cleaned
 * @returns {object} object where no undefined values present
 */
const getCleanedObject = (obj = {}) => {
	const tempObj = {...obj};
	Object.keys(tempObj).forEach(key => {
		if (tempObj[key] === undefined) {
			delete tempObj[key];
		}
	});
	return tempObj;
};

const defaults = {
	SEARCH_UPDATE_EVENT: 'SEARCH_UPDATED',
	FILTER: {
		ADD: 'add filter',
		REMOVE: 'remove filter',
		CHANGE: 'change filter'
	}
};

/**
 *
 * @param {string} eventAction eventAction
 * @param {string} eventName eventName
 * @param {string} componentName componentName
 * @param {object} relatedProduct relatedProduct
 * @param {string} clickID clickId
 * @param {string} elementName elementName
 * @param {string} label label
 * @param {string} pos pos
 * @param {string} relatedFilter relatedFilter
 * @param {string} targetURL targetURL
 * @param {array} relatedPromotion relatedPromotion
 * @param {*} value value
 * @returns {{eventInfo: {}, search: {}, attributes: {}}} trackingObject trackingObject
 * @private
 */
const _createTrackingObject = (
	{
		eventAction,
		eventName,
		componentName,
		relatedProduct,
		clickID,
		elementName,
		label,
		value,
		pos,
		relatedFilter,
		targetURL,
		relatedPromotion
	} = {}) => {
	return {
		eventInfo: getCleanedObject({eventAction, eventName}),
		attributes: getCleanedObject({
			componentName,
			relatedProduct,
			clickID,
			elementName,
			label,
			value,
			pos,
			relatedFilter,
			targetURL,
			relatedPromotion
		})
	};
};

/**
 * handleMasApply
 * @param {object} trackingObject tracking object
 * @returns {object | void} tracking extension object
 */
const handleMasApply = async (trackingObject) => {
	const isMasApplyButton = trackingObject.event.target.closest('.sc-j-mas-continue');
	if (isMasApplyButton) {
		const locationEditContainer = document.querySelector('.sc-location-indicator-edit');
		if (locationEditContainer) {
			const inputValue = locationEditContainer.innerHTML;
			return _createTrackingObject({
				eventAction: 'layer_close_click',
				eventName: 'vtp apply location',
				value: inputValue
			});
		}
	}
	return null;
};

/**
 * handleNewSearchButtonClick
 * @param {object} trackingObject tracking object
 * @returns {object | void} tracking extension object
 */
const handleNewSearchButtonClick = async (trackingObject) => {
	const isNewSearchButton = trackingObject.event.target.classList.contains('sc-new-search') ? trackingObject.event.target : trackingObject.event.target.closest('.nm-icon-search-new');
	if (isNewSearchButton) {
		return _createTrackingObject({
			eventAction: 'internal_link',
			eventName: 'vtp new search'
		});
	}
	return null;
};

/**
 * @deprecated
 * handlePhoneNumberClick
 * @param {object} trackingObject tracking object
 * @returns {object | void} tracking extension object
 */
export const handlePhoneNumberClick = async (trackingObject) => {
	const isPhoneNumberLink = trackingObject.event.target.classList.contains('sc-dealerinfo-phone-number') || ((trackingObject.event.target.closest('.sc-dealer-contact') || trackingObject.event.target.closest('.sc-cta-button[data-button-type="phone"]') || trackingObject.event.target.closest('.sc-detail-carinfo-dealer-phone')));
	if (isPhoneNumberLink && (trackingObject.event.target.href || trackingObject.event.target.parentElement.href || '').indexOf('tel') > -1) {
		const tileOrCarInfo = trackingObject.event.target.closest('.sc-tiles-item.sc-car-tile') || trackingObject.event.target.closest('.sc-detail-module.sc-detail-carinfo') || document.querySelector('.sc-detail-module.sc-detail-carinfo') || document.querySelector('[data-tracking-id="vtp-carinfo"]');
		if (!tileOrCarInfo){
			return null;
		}
		const {vehicleId = ''} = tileOrCarInfo.dataset;
		const relatedProduct = getRelatedProduct(vehicleId);

		return _createTrackingObject({
			eventAction: 'call',
			eventName: 'vtp call dealer',
			relatedProduct
		});
	}
	return null;
};

/**
 * handleFinancingApplyButtonClick
 * @param {object} trackingObject tracking object
 * @returns {object | void} tracking extension object
 */
const handleFinancingApplyButtonClick = async (trackingObject) => {
	const isFinanceApplyButton = trackingObject.event.target.closest('.js-sc-finance-form-update');
	if (isFinanceApplyButton) {
		const carInfo = document.querySelector('[data-tracking-id="vtp-carinfo"]');
		if (carInfo && carInfo.dataset) {
			const {vehicleId = ''} = carInfo.dataset;
			const relatedProduct = getRelatedProduct(vehicleId);
			return _createTrackingObject({
				eventAction: 'layer_close_click',
				eventName: 'vtp apply finance calculation',
				relatedProduct
			});
		}
	}
	return null;
};

/**
 * handleFinancingCalculateButtonClick
 * @param {object} trackingObject tracking object
 * @returns {object | void} tracking extension object
 */
const handleFinancingCalculateButtonClick = async (trackingObject) => {
	const isFinanceCalculateButton = trackingObject.event.target.closest('.js-sc-finance-form-calculate');
	if (isFinanceCalculateButton) {
		const carInfo = document.querySelector('[data-tracking-id="vtp-carinfo"]');
		if (carInfo && carInfo.dataset) {
			const {vehicleId = ''} = carInfo.dataset;
			const relatedProduct = getRelatedProduct(vehicleId);
			return _createTrackingObject({
				eventAction: 'calculate',
				eventName: 'vtp finance calculation',
				relatedProduct
			});
		}
	}
	return null;
};

/**
 * @deprecated
 * handleChangeRateButtonClick
 * @param {object} trackingObject tracking object
 * @returns {object | void} tracking extension object
 */
export const handleChangeRateButtonClick = async (trackingObject) => {
	const isChangeRateButton = trackingObject.event.target.closest('.sc-detail-change-rate');
	if (isChangeRateButton) {
		const carInfo = trackingObject.event.target.closest('[data-tracking-id="vtp-carinfo"]');
		if (carInfo && carInfo.dataset) {
			const {vehicleId = ''} = carInfo.dataset;
			const relatedProduct = getRelatedProduct(vehicleId);
			return _createTrackingObject({
				eventName: 'vtp change rate',
				eventAction: 'layer_open_click',
				relatedProduct
			});
		}
	}
	return null;
};

/**
 * handlePdfClick
 * @param {object} trackingObject tracking object
 * @returns {object | void} tracking extension object
 */
export const handlePdfClick = async (trackingObject) => {
	const isPdfDownloadButton = trackingObject.event.target.closest('.nm-icon-pdf');
	if (isPdfDownloadButton) {
		const container = trackingObject.event.target.closest('.sc-tb-options-link');
		if (container) {
			const {vehicleId = ''} = container.dataset;
			const relatedProduct = getRelatedProduct(vehicleId);

			return _createTrackingObject({
				eventAction: 'download',
				eventName: 'vtp print',
				relatedProduct
			});
		}
	}
	return null;
};

/**
 * handleAudicodeClick
 * @param {object} trackingObject tracking object
 * @returns {object | void} tracking extension object
 */
const handleAudicodeClick = async (trackingObject) => {
	const audicodeButton = trackingObject.event.target.closest('.sc-sb-audicode');

	if (audicodeButton) {
		const audicodeFlyout = trackingObject.event.target.closest('.sc-sb-audicode-flyout');
		const isClickInsideFlyout = audicodeFlyout ? audicodeFlyout.contains(trackingObject.event.target) : null;
		const isAudicodeFlyoutVisible = !audicodeButton.classList.contains('sc-sb-audicode-flyout-active');

		if (!isClickInsideFlyout && !isAudicodeFlyoutVisible) {
			const {vehicleId = ''} = audicodeButton.dataset;
			const relatedProduct = getRelatedProduct(vehicleId);
			const label = (audicodeButton.querySelector('.sc-tb-options-link .sc-tb-linktext')) ? audicodeButton.querySelector('.sc-tb-options-link .sc-tb-linktext').innerText : 'Audicode';

			const resultObject = _createTrackingObject({
				eventAction: 'tooltip',
				eventName: 'vtp audi code',
				value: audicodeButton.dataset.audicode,
				label,
				relatedProduct
			});
			return resultObject;
		}
	}
	return null;
};

/**
 * handleCompareButtonClick
 * @param {object} trackingObject tracking object
 * @returns {object | void} tracking extension object
 */
const handleCompareButtonClick = async (trackingObject) => {
	const isCompareButton = trackingObject.event.target.closest('.sc-compare-panel-collapsed-compare-button-link') || trackingObject.event.target.closest('.sc-compare-panel-expanded-compare-button-link');
	if (isCompareButton) {
		return _createTrackingObject({
			eventAction: 'internal_link',
			eventName: 'vtp go to compare'
		});
	}
	return null;
};

/**
 * handleFavoriteCLick
 * @param {object} trackingObject tracking object
 * @returns {object | void} tracking extension object
 */
const handleFavoriteCLick = async (trackingObject) => { // eslint-disable-line max-statements
	const favoriteButton = trackingObject.event.target.closest('.js-favorite');
	if (favoriteButton) {

		const tile = favoriteButton.closest('.sc-tiles-item');
		const isFavoritePage = scPage.isFavoritesPage();


		if ((tile && tile.contains(favoriteButton)) || scPage.isDetailsPage(location.href) || favoriteButton.closest('.sc-j-compare-car')) {
			const {vehicleId = ''} = favoriteButton.dataset;
			const favoriteStateActive = favoriteButton.classList.contains('active');
			if (isFavoritePage && favoriteStateActive) {
				return {};
			}

			const relatedProduct = getRelatedProduct(vehicleId);
			let resultObject = _createTrackingObject({
				eventAction: 'favorite',
				eventName: favoriteStateActive ? 'vtp add to favorites' : 'vtp remove from favorites',
				relatedProduct,
				targetURL: '',
				label: favoriteStateActive ? favoriteButton.getAttribute('data-text') : favoriteButton.getAttribute('data-activetext') || favoriteButton.getAttribute('data-active-text')
			});
			return resultObject;
		}
		return {};
	}
	return null;
};

/**
 * handleFavoriteDeleteButtonClick
 * @param {object} trackingObject trackingObject
 * @returns {Promise<{eventInfo: {}, search: {}, attributes: {}}>} created tracking object
 */
const handleFavoriteDeleteButtonClick = async (trackingObject) => {
	const favoriteDeleteButton = trackingObject.event.target.closest('.sc-favorite-delete');
	if (favoriteDeleteButton) {

		const {vehicleId = ''} = trackingObject.event.target.dataset;
		const relatedProduct = getRelatedProduct(vehicleId);
		return _createTrackingObject({
			eventAction: 'favorite',
			eventName: 'vtp remove from favorites',
			relatedProduct,
			targetURL: ''
		});
	}
	return null;
};

/**
 * handleAnotherVtpStep
 * @param {object} trackingObject trackingObject
 * @returns {{eventInfo: {}, search: {}, attributes: {}}} created tracking object
 */
const handleAnotherVtpStep = async (trackingObject) => {
	const vtpStep = trackingObject.event.target.closest('.sc-main-navigation-item, .sc-main-navigation-result-button');

	if (vtpStep) {
		const labelEl = vtpStep.querySelector('.sc-main-navigation-result-label');
		const lbl = (!!labelEl && labelEl.innerText) ? labelEl.innerText : '';

		return _createTrackingObject({
			eventAction: 'internal_link',
			eventName: 'vtp change step',
			label: vtpStep.classList.contains('sc-main-navigation-result-button') ? STCK_STORE.state.totalCount + ' ' + lbl : undefined
		});
	}
	return null;
};

/**
 * handleLoadMoreButtonClick
 * @param {object} trackingObject trackingObject
 * @returns {{eventInfo: {}, search: {}, attributes: {}}} created tracking object
 */
const handleLoadMoreButtonClick = async (trackingObject) => {
	const loadMoreButton = trackingObject.event.target.closest('.sc-j-load-more');
	if (loadMoreButton) {
		return _createTrackingObject({
			eventAction: 'content',
			eventName: 'vtp show more cars'
		});
	}
	return null;
};

/**
 * handleContactLinkClick
 * @param {object} trackingObject trackingObject
 * @returns {Promise<{eventInfo: {}, search: {}, attributes: {}}>} created tracking object
 */
const handleContactLinkClick = async (trackingObject) => {
	if (trackingObject?.action !== 'track-contact-click') { return null; }

	const buttonElementWithId = trackingObject.event.target.closest('[data-vehicle-id]');

	if (buttonElementWithId) {
		const {vehicleId = ''} = buttonElementWithId.dataset;
		const relatedProduct = getRelatedProduct(vehicleId);
		return _createTrackingObject({
			eventAction: 'layer_open_click',
			eventName: 'vtp contact',
			value: '',
			relatedProduct
		});
	}

	return null;
};

const _hasRangeFilterValueChanged = (activeFilter_, previousFilter_, filter_) => {
	const activeFilterValues = activeFilter_.get(filter_.id).values;
	const previousFilterValues = previousFilter_.get(filter_.id).values;

	if (!activeFilterValues || !previousFilterValues) {
		return false;
	}

	if (activeFilterValues.length !== previousFilterValues.length) {
		return true;
	}

	if (!(activeFilterValues.every(e => previousFilterValues.includes(e)))) {
		return true;
	}

	return false;
};

/**
 * getChangedFiltersArray
 * @param {array<object>} allFilter allFilter
 * @param {map} activeFilters activeFilters
 * @param {map} activeFiltersBeforeChange activeFiltersBeforeChange
 * @returns {{changedArray: Array, operation: string}} object containing changed array and operation
 */
const getChangedFiltersArray = (allFilter, activeFilters, activeFiltersBeforeChange) => {
	const changedArray = [];
	let operation = '';

	allFilter.forEach(filter => {
		if (activeFilters.has(filter.id) && activeFiltersBeforeChange.has(filter.id)) {
			if (_hasRangeFilterValueChanged(activeFilters, activeFiltersBeforeChange, filter)) {
				changedArray.push(filter);
				operation = defaults.FILTER.CHANGE;
			}
		}
		else if (activeFilters.has(filter.id) && !activeFiltersBeforeChange.has(filter.id)) {
			changedArray.push(filter);
			operation = defaults.FILTER.ADD;
		}
		else if (!activeFilters.has(filter.id) && activeFiltersBeforeChange.has(filter.id)) {
			changedArray.push(filter);
			operation = defaults.FILTER.REMOVE;
		}
	});
	return {changedArray, operation};
};

/**
 * _getGeoOrAllValues
 * @param {object} filter filter
 * @returns {*[]} values values
 * @private
 */
const _getGeoOrAllValues = (filter = {}) => {
	return filter.id === 'geo' ? [filter.values[filter.values.length - 1]] : filter.values;
};

/**
 * _getGeoFilter
 * @returns {{}|{count: number, id: string}} geo object
 * @private
 */
const _getGeoFilter = () => {
	if (!SELECTORS.FILTERS.getFilterItemsRaw(STCK_STORE.state).filter(filterItem => filterItem.id === 'geo').length) {
		return {id: 'geo', count: 0};
	}
	return {};
};

/**
 * _handleSearchUpdated
 * @param {map} activeFiltersBeforeChange activeFiltersBeforeChange
 * @returns {Promise<{eventInfo: {}, search: {}, attributes: {}}>} related filter
 */
export const _handleSearchUpdated = async (activeFiltersBeforeChange) => {
	const allFilter = [...SELECTORS.FILTERS.getFilterItemsRaw(STCK_STORE.state), _getGeoFilter()];
	const activeFilters = SELECTORS.FILTERS.getAllActiveFiltersMap(STCK_STORE.state);
	const {changedArray, operation} = getChangedFiltersArray(allFilter, activeFilters, activeFiltersBeforeChange);
	const relatedFilter = changedArray.map(filter => {
		return {
			id: filter.id,
			values: filter.values ? _getGeoOrAllValues(filter) : []
		};
	});

	if (relatedFilter && relatedFilter.length) {
		return _createTrackingObject({
			eventAction: 'filter',
			eventName: operation,
			relatedFilter: relatedFilter,
			targetURL: ''
		});
	}
	return null;
};

/**
 * handleFilterChange
 * @returns {Promise<any>} returns promise resolving tracking object
 */
const handleFilterChange = () => {
	const activeFilters = SELECTORS.FILTERS.getAllActiveFiltersMap(STCK_STORE.state);

	return new Promise((resolve, reject) => {
		document.addEventListener(defaults.SEARCH_UPDATE_EVENT, async function searchUpdatedHandler() {
			document.removeEventListener(defaults.SEARCH_UPDATE_EVENT, searchUpdatedHandler);
			const trackingObject = await _handleSearchUpdated(activeFilters).catch(err => reject(err));
			resolve(trackingObject);
		});
	});
};

/**
 * handleFilterClick - filter, group, filterchip, geo submit
 * @param {object} trackingObject trackingObject
 * @returns {Promise<any>} returns promise resolving tracking object
 */
const handleFilterClick = async (trackingObject) => {
	const filterItem = trackingObject.event.target.closest('.sc-filter-item, .sc-filter-range, .sc-filter-select-all, .sc-filter-options-item-filter, #sc-lf-search-submit, .sc-j-filter-options-reset');
	if (filterItem) {
		return handleFilterChange();
	}
	return null;
};

/**
 * handleEecLinkClick
 * @param {object} trackingObject trackingObject
 * @returns {Promise<any>} returns promise resolving tracking object
 */
const handleEecLinkClick = async (trackingObject) => {
	const eecLink = trackingObject.event.target.closest('.sc-js-eec-link');

	if (eecLink) {
		const linkText = eecLink.querySelector('.audi-link-s__text');
		const label = linkText ? linkText.innerText : '';
		const {id: vehicleId = ''} = trackingObject.event.currentTarget.dataset;
		const relatedProduct = getRelatedProduct(vehicleId);

		return _createTrackingObject({
			eventAction: 'layer_open_click',
			eventName: 'vtp open consumption layer',
			relatedProduct,
			label
		});
	}

	return null;
};

/**
 * handleEecLayerClick
 * @param {object} trackingObject trackingObject
 * @returns {Promise<any>} returns promise resolving tracking object
 */
const handleEecLayerClick = async (trackingObject) => {
	const closeButton = trackingObject.event.target.closest('.modal-layer--close-button');

	if (closeButton) {
		return _createTrackingObject({
			eventAction: 'layer_close_click',
			eventName: 'vtp close consumption layer'
		});
	}

	return null;
};

const handleComparePanelRemoveButtonClick = (trackingObject) => {
	const compareRemoveButton = trackingObject.event.target.closest('.js-remove-compare');

	if (compareRemoveButton) {
		const vehicleId = compareRemoveButton.getAttribute('data-vehicle-id') || '';
		const eventName = 'vtp remove from compare';
		const relatedProduct = getRelatedProduct(vehicleId);

		return _createTrackingObject({
			eventAction: 'content',
			eventName,
			value: '',
			relatedProduct
		});
	}

	return null;
};


const handleAddAndRemoveCompareButtonClick = (trackingObject) => {
	const compareButton = trackingObject.event.target.closest('.js-add-compare');

	if (compareButton) {
		const state = SELECTORS.VEHICLES.getCompareVehicleIdsState(STCK_STORE.state);
		const carTile = compareButton.closest('.sc-j-car-tile');
		if (!carTile) {
			return null;
		}
		const vehicleId = carTile.getAttribute('data-vehicle-id') || '';
		const index = state.indexOf(vehicleId);

		const isTypeRemove = index === -1;
		const eventName = isTypeRemove ? 'vtp remove from compare' : 'vtp add to compare';

		const {text = '', activeText = ''} = trackingObject.event.currentTarget.dataset;

		const relatedProduct = getRelatedProduct(vehicleId);

		return _createTrackingObject({
			eventAction: 'content',
			eventName,
			value: '',
			pos: isTypeRemove ? undefined : index + 1,
			label: isTypeRemove ? activeText : text,
			relatedProduct
		});
	}

	return null;
};

let currentView = "";

const handleViewChangeClick = async (trackingObject) => {
	const switchButton = trackingObject.event.target.closest('.nm-icon-grid-view, .nm-icon-list-view');
	const buttonContainer = trackingObject.event.target.closest('.sc-filter-view-option');

	if (buttonContainer && currentView === "") {
		currentView = buttonContainer.dataset.initialview;
	}

	if (switchButton) {
		const gridOrList = switchButton.dataset.view;
		if (gridOrList !== currentView) {
			currentView = gridOrList;
			return _createTrackingObject({
				eventAction: 'content',
				eventName: `vtp ${gridOrList}`,
				value: gridOrList
			});
		}
		currentView = gridOrList;
	}

	return null;
};

const handleLiveDemoClick = async (trackingObject) => {
	const liveDemoButton = trackingObject.event.target.closest('.sc-cta-button[data-button-type="liveDemo"]');

	if (liveDemoButton) {
		return _createTrackingObject({
			eventAction: 'exit_link',
			eventName: 'vtp click on live consulting'
		});
	}

	return null;
};

const handleSortChangeClick = async (trackingObject) => {
	const sortDropDown = trackingObject.event.target.closest('.sorting-dropdown-element #possibleSortAttributes');

	if (sortDropDown) {
		return new Promise((resolve) => {
			const outsideClickHandler = () => {
				// eslint-disable-next-line no-use-before-define
				clearTimeout(timeout);
				document.body.removeEventListener('click', outsideClickHandler);
				// eslint-disable-next-line no-use-before-define
				sortDropDown.removeEventListener('change', changeHandler);
				return null;
			};

			const changeHandler = (event) => {
				// eslint-disable-next-line no-use-before-define
				clearTimeout(timeout);
				document.body.removeEventListener('click', outsideClickHandler);
				sortDropDown.removeEventListener('change', changeHandler);

				const selectedIndex = event.target.selectedIndex;
				const selectedOption = [...event.target.options][selectedIndex];
				const label = (selectedOption.innerText || '').trim();

				resolve(_createTrackingObject({
					eventAction: 'content',
					eventName: 'vtp sort',
					value: event.target.value,
					label
				}));
			};

			sortDropDown.addEventListener('change', changeHandler);
			document.body.addEventListener('click', outsideClickHandler);
			const timeout = setTimeout(() => {
				document.body.removeEventListener('click', outsideClickHandler);
				// eslint-disable-next-line no-use-before-define
				sortDropDown.removeEventListener('change', changeHandler);
				return null;
			}, 3500);
			// eslint-disable-next-line no-unused-vars,no-empty-function
		}).catch(e => null);
	}

	return null;
};

const handleCampaignOpenClick = async (trackingObject) => {
	const handleCampaignOpenClickButton = trackingObject.event.target.closest('.sc-details-campaigns-swipe-campaign-link');

	if (handleCampaignOpenClickButton) {
		const {campaignId, campaignHeadline} = handleCampaignOpenClickButton.dataset;

		if (!campaignId) {
			return null;
		}

		return _createTrackingObject({
			eventName: 'vtp offer teaser click',
			value: campaignHeadline,
			relatedPromotion: [{promotionName: campaignId}]
		});
	}

	return null;
};

const handleCampaignNavigationClick = async (trackingObject) => {
	const campaignNextButton = trackingObject.event.target.closest('.sc-details-campaigns-swipe-element .sc-j-swipe-gallery-navigation-next');
	const campaignBackButton = trackingObject.event.target.closest('.sc-details-campaigns-swipe-element .sc-j-swipe-gallery-navigation-prev');
	const campaignPaginationButtonButton = trackingObject.event.target.closest('.sc-details-campaigns-swipe-element' +
		' .sc-j-swipe-gallery-dots .sc-j-swipe-gallery-dot');

	if (campaignNextButton || campaignBackButton || campaignPaginationButtonButton) {
		const vehicleId = scPage.getVehicleIdFromUrl();
		const relatedProduct = getRelatedProduct(vehicleId);

		return _createTrackingObject({
			elementName: campaignPaginationButtonButton ? 'pagination' : 'arrow',
			eventAction: 'navigation',
			eventName: 'vtp next promotion teaser',
			value: '',
			relatedProduct
		});
	}

	return null;
};

const getAllInPricingInfoIOrCloseButton = trackingObject => {
	const allInPricingInfoI = trackingObject.event.target.closest('a.sc-financing-allinpricing-info-icon-link');
	const allInPricingCloseButton = trackingObject.event.target.closest('.sc-modal-financing-pricing-button-close');

	return {allInPricingInfoI, allInPricingCloseButton};
};

const handleAllInPricingInfoIClick = async (trackingObject) => {
	const {allInPricingInfoI, allInPricingCloseButton} = getAllInPricingInfoIOrCloseButton(trackingObject);

	if (allInPricingInfoI || allInPricingCloseButton) {
		const vehicleId = scPage.getVehicleIdFromUrl();
		const relatedProduct = getRelatedProduct(vehicleId);

		const {layerHeadline = ''} = allInPricingInfoI ? allInPricingInfoI.dataset : '';

		return _createTrackingObject({
			elementName: 'i icon',
			eventName: allInPricingInfoI ? 'vtp info layer open' : 'vtp info layer close',
			eventAction: 'content',
			label: '',
			value: layerHeadline,
			relatedProduct
		});
	}

	return null;
};

const handleOptionsClick = async (trackingObject) => {
	const optionsButton = trackingObject.event.target.closest('.sc-tb-options-label');
	const label = optionsButton ? optionsButton.textContent : '';

	if (optionsButton) {
		return _createTrackingObject({
			eventName: 'vtp click on options',
			eventAction: 'navigation',
			value: '',
			label,
			targetURL: ''
		});
	}

	return null;
};

const getInfoIOrCloseButton = trackingObject => {
	const tilesFinancingInfoI = trackingObject.event.target.closest('.js-sc-info-layer-icon');
	const tilesFinancingInfoLayerCloseButton = trackingObject.event.target.closest('.sc-rate-info-layer-close-button');

	return {tilesFinancingInfoI, tilesFinancingInfoLayerCloseButton};
};

const handleTilesFinancingInfoIClick = async (trackingObject) => {
	const {tilesFinancingInfoI, tilesFinancingInfoLayerCloseButton} = getInfoIOrCloseButton(trackingObject);

	if (tilesFinancingInfoI || tilesFinancingInfoLayerCloseButton) {
		const financeElement = (tilesFinancingInfoI || tilesFinancingInfoLayerCloseButton).closest('.sc-car-tile-price-retail-price');
		const financingInfoLayer = financeElement ? financeElement.querySelector('.js-sc-info-layer') : null;

		if (!financingInfoLayer) {
			return null;
		}

		const {vehicleId = ''} = financingInfoLayer.dataset;
		const relatedProduct = getRelatedProduct(vehicleId);
		const productLabel = financingInfoLayer.querySelector('.sc-rate-product-label');
		const value = productLabel ? productLabel.textContent : '';

		const createdTrackingObject = _createTrackingObject({
			eventAction: 'content',
			eventName: tilesFinancingInfoI ? 'vtp info layer open' : 'vtp info layer close',
			value,
			relatedProduct,
			label: ''
		});

		if (tilesFinancingInfoI) {
			createdTrackingObject.attributes.elementName = 'i icon';
		}

		return createdTrackingObject;
	}

	return null;
};


const handleTilesTabClick = async (trackingObject) => {
	const tilesTab = trackingObject.event.target.closest('.sc-car-tile-tab-selector-label');

	if (tilesTab) {
		const label = tilesTab.textContent;
		const vehicleTabElement = tilesTab.closest('vehicle-tab-element');
		const {vehicleId = ''} = vehicleTabElement.dataset;
		const relatedProduct = getRelatedProduct(vehicleId);

		return _createTrackingObject({
			eventAction: 'content',
			eventName: 'vtp small tab change',
			label,
			relatedProduct
		});
	}

	return null;
};

const getDrawerLabel = (element, openState) => {
	const {state, closeState} = element.dataset;

	if (state || closeState) {
		return openState ? closeState : state;
	}

	return element.innerText.trim();
};

const handleDrawerClick = async (trackingObject) => {
	const showMoreButton = trackingObject.event.target.closest(' .sc-details-aoz-showmore, .sc-compare-show-all .sc-compare-collapse-text, .sc-compare-category-header,' +
		' .sc-detail-td-showmore');

	if (showMoreButton) {

		const showMoreLabelForAttribute = showMoreButton.getAttribute('for') || '';
		const showMoreLabelInput = document.getElementById(showMoreLabelForAttribute);

		if (!showMoreLabelInput) {
			return null;
		}

		const openStateBeforeClick = !!showMoreLabelInput.checked;

		const {vehicleId = ''} = scPage.getVehicleIdFromUrl();
		const relatedProduct = getRelatedProduct(vehicleId);
		const label = getDrawerLabel(showMoreButton, openStateBeforeClick);

		return _createTrackingObject({
			eventAction: 'content',
			eventName: openStateBeforeClick ? 'vtp drawer close' : 'vtp drawer open',
			relatedProduct,
			label
		});
	}

	return null;
};

const handleTabClick = async (trackingObject) => {
	const panelTab = trackingObject.event.target.closest('.nm-panel-tab-expand');
	const otherTab = trackingObject.event.target.closest('#sc-mf-label-bodytypes, #sc-mf-label-carlines');

	if (panelTab || otherTab) {
		const componentName = panelTab ? 'nm-panel-tab' : undefined;
		return _createTrackingObject({
			eventAction: 'content',
			eventName: 'vtp filter sub tab change',
			targetURL: '',
			elementName: 'text link',
			value: '',
			pos: '',
			componentName
		});
	}

	return null;
};

const handleLiteReservationClick = async (trackingObject) => {
	const liteReservationButton = trackingObject.event.target.closest('.sc-cta-button[data-button-type="liteReservation"]');

	if (liteReservationButton) {
		return _createTrackingObject({
			eventAction: 'exit_link',
			eventName: 'vtp click on vehicle lite reservation',
			targetURL: '',
			value: '',
			pos: ''
		});
	}

	return null;
};

const handleReservationClick = async (trackingObject) => {
	const reservationButton = trackingObject.event.target.closest('.sc-cta-button[data-button-type="reservation"]');

	if (reservationButton) {
		return _createTrackingObject({
			eventAction: 'exit_link',
			eventName: 'vtp click on vehicle reservation',
			targetURL: '',
			value: '',
			pos: ''
		});
	}

	return null;
};

const handleEcomClick = async (trackingObject) => {
	const ecomButton = trackingObject.event.target.closest('.sc-cta-button[data-button-type="ecom"]');
	const ecomLink = trackingObject.event.target.closest('.audi-link-s[data-button-type="ecom"]');

	const {vehicleId = ''} = ecomLink ? ecomLink.closest('.sc-vehicle-tiles-button-element').dataset : scPage.getVehicleIdFromUrl();
	const relatedProduct = getRelatedProduct(vehicleId);
	if (ecomButton || ecomLink) {
		return _createTrackingObject({
			eventAction: 'exit_link',
			eventName: 'vtp click on oneaudi shop',
			targetURL: '',
			value: '',
			pos: '',
			relatedProduct
		});
	}

	return null;
};

const handleAdvantagesInfoIClick = async (trackingObject) => {
	const advantagesInfoI = trackingObject.event.target.closest('.sc-tile-advantages-info');

	if (advantagesInfoI) {
		const resultTile = advantagesInfoI.closest('.sc-j-car-tile');
		const vehicleId = resultTile.getAttribute('data-vehicle-id') || '';
		const relatedProduct = getRelatedProduct(vehicleId);
		return _createTrackingObject({
			eventAction: 'layer_open_click',
			eventName: 'vtp info layer open',
			value: '',
			relatedProduct
		});
	}
	return null;
};

const clickHandlers = {
	handleTabClick,
	handleAllInPricingInfoIClick,
	handleTilesFinancingInfoIClick,
	handleTilesTabClick,
	handleFavoriteCLick,
	handleCompareButtonClick,
	handleEecLinkClick,
	handleEecLayerClick,
	handleFavoriteDeleteButtonClick,
	handleFilterClick,
	handleContactLinkClick,
	handleLoadMoreButtonClick,
	handlePdfClick,
	handleAnotherVtpStep,
	handleMasApply,
	handleNewSearchButtonClick,
	handleFinancingApplyButtonClick,
	handleFinancingCalculateButtonClick,
	handleAudicodeClick,
	handleViewChangeClick,
	handleSortChangeClick,
	handleAddAndRemoveCompareButtonClick,
	handleComparePanelRemoveButtonClick,
	handleLiveDemoClick,
	handleCampaignOpenClick,
	handleCampaignNavigationClick,
	handleDrawerClick,
	handleLiteReservationClick,
	handleReservationClick,
	handleEcomClick,
	handleOptionsClick,
	handleAdvantagesInfoIClick
};
export {clickHandlers};
