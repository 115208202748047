import {clickEventService} from 'core-tracking-objects';
import {clickHandlers as CLICK_HANDLERS_LOOKUP} from './click-tracking-handler';

class ClickTrackingStockcars {
	static get clickSelectors() {
		return [
			'modal-consumption-emission-layer-element .modal-layer--close-button',
			'button',
			'#js-sc-contact-button',
			'.sc-sb-audicode',
			'.sc-filter-item',
			'.sc-filter-range',
			'.sc-filter-select-all',
			'.sorting-dropdown-element #possibleSortAttributes',
			'.sc-rate-info-layer-close-button',
			'.sc-car-tile-tab-selector-label',
			'.sc-j-swipe-gallery-dots .sc-j-swipe-gallery-dot',
			'.sc-j-swipe-gallery-navigation-next',
			'.sc-j-swipe-gallery-navigation-prev',
			'.sc-detail-se-showmore',
			'.sc-detail-oe-showmore',
			'.sc-details-aoz-showmore',
			'.sc-detail-td-showmore',
			'.sc-compare-show-all .sc-compare-collapse-text',
			'.sc-compare-category-header',
			'#sc-mf-label-carlines',
			'#sc-mf-label-bodytypes',
			'.nm-panel-tab-expand',
			'.sc-tb-options-label',
			'.sc-tile-advantages-info'
		];
	}

	/**
	 * initialize
	 * @returns {void} void
	 */
	initialize() {
		this.handleClickTracking = this.handleClickTracking.bind(this);
		clickEventService.register(this.handleClickTracking);

		ClickTrackingStockcars.clickSelectors.forEach(filterSelector => {
			clickEventService.registerClickSelector(filterSelector);
		});

	}

	/**
	 * handleClickTracking
	 * @param {event} event event
	 * @param {object} clickHandlers clickHandlers
	 * @returns {Promise<{}>} returns result from handler or empty object
	 */
	async handleClickTracking(event, clickHandlers = CLICK_HANDLERS_LOOKUP) {
		const handlers = Object.values(clickHandlers);
		const {trackingAction: action = ''} = event.currentTarget.dataset;

		for (let index = 0; index < handlers.length; index++) {
			const result = await handlers[index]({action, event});
			if (result) {
				return result;
			}
		}
		return {};
	}

}

const clickTrackingStockcars = new ClickTrackingStockcars();
export {clickTrackingStockcars, ClickTrackingStockcars};
